.table-wrap {
  overflow-x: auto;
  overflow-y: visible;

  table {
    border-collapse: collapse;
  }
  th {
    text-align: left;
    padding: 0.5em;
  }
  td {
    padding: 0.5em;
    border-top: 1px solid $medium-gray;
    vertical-align: middle;
  }
}
