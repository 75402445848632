.dialog-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: background-color 150ms ease-in-out;
  z-index: 1100;
  background-color: rgba(255, 255, 255, 0.5);
  &.hidden {
    display: block !important;
    background-color: transparent;
    .dialog {
      transform: scaleY(0);
    }
  }
}

.dialog {
  padding: 1em;
  border-radius: 0 0 $border-radius $border-radius;
  border: 1px solid $almost-white;
  border-top: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  background-color: $white;
  width: 30em;
  max-width: 100vw;
  margin: 0 auto;
  transform-origin: top center;
  transform: scaleY(1);
  transition: transform 150ms ease-in-out;

  &.detached {
    margin-top: 7em;
    width: 40em;
    border: 2px solid #b0afaf;
    border-radius: 0.45em;
  }
  .modal-btn {
    border: none;
    background: rgba(0,0,0,0);
    flex-grow: 0;
    flex-shrink: 0;
    /* float: right; */
  }
}
