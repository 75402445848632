.pricing-table {
  text-align: center;
  padding: 1em;
  > .price-column {
    border: 1px solid #b3d6b3;
    &:not(:last-child) {
      border-right: none;
    }
    &:nth-child(odd) {
      background-color: #d9ebd9;
    }
    &:nth-child(even) {
      background-color: #cfe0cf;
    }
    > .price-description-row {
      height: 4em;
    }
    > .price-value-row {
      height: 4.6em;
      .price-value-div {
        display: inline-flex;
        align-items: center;
        > .price-value {
          font-size: 2.4em;
          font-weight: bold;
        }
        > .price-currency {
          font-size: 2.4em;
          font-weight: bold;
          margin-left: 0.2em;
        }
        > .price-frequency {
          line-height: 1em;
          text-align: left;
          margin-left: 0.3em;
        }
      }
    }
    > .price-button-row {
      height: 3.5em;
      .price-subscribe-text {
        padding: 0.5em 0;
        font-weight: bold;
      }
    }
    > .price-includes-row {
      ul {
        padding: 0;
        list-style: none;
        line-height: 1.4em; // 1.2em
        li {
          > span {
            &:before {
              content: "✓ ";
            }
          }
        }
      }
    }
  }
  .price-title {
    font-size: 2.2em;
    font-weight: bold;
    text-align: center;
  }
  .subscribe-button {
    min-width: 10em;
  }
}

/* some hacks done here, had problems with collapse areas and no time to make it properly; best not to use #main.landing .faq  */
.pricing-panel {
  margin-top: -2em;
  .faq {
    margin-top: -2em !important;
    padding-top: 4.5em !important;
    h3 {
      font-size: 1em;
    }
    &::after {
      background: none !important;
    }
  }
}
