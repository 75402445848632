.event-card {
  display: block;
  border: 1px solid $almost-white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center center;

  a,
  a:hover {
    color: inherit;
  }

  .btn-design-settings,
  .btn-edit-event,
  .btn-copy-event {
    z-index: 500;
    background-color: $bg-color;
    position: absolute;
    border: 1px solid $almost-white;
    border-radius: 50%;
    color: $text-color;
    display: flex;
    > .fa {
      margin: auto;
    }
    @include on("xl", "down") {
      width: 6.6em;
      height: 6.6em;
      top: -3.3em;
      right: -3.3em;
      padding: 2.7em 2.7em 0 0;
      > .fa {
        font-size: 1.5em;
      }
    }
    @include on("sm", "down") {
      width: 6em;
      height: 6em;
      top: -3em;
      right: -3em;
      padding: 2.5em 2.5em 0 0;
      > .fa {
        font-size: 1.2em;
      }
    }
  }

  .btn-design-settings {
    background-image: url(../images/icons/rainbow.embed.svg);
    background-size: 0 0;
    background-position: center center;
    background-repeat: no-repeat;
    transition: background-size 150ms ease-in-out;
    &:hover {
      color: $white;
      background-size: 100% 100%;
    }

    @include on("xl", "down") {
      width: 7em;
      height: 7em;
      bottom: -3.5em;
      right: -3.5em;
      top: unset;
      padding: 0 2.7em 2.7em 0;
      > .fa {
        font-size: 1.4em;
      }
    }
    @include on("sm", "down") {
      width: 6em;
      height: 6em;
      bottom: -3em;
      right: -3em;
      padding: 0 2.5em 2.5em 0;
      > .fa {
        font-size: 1em;
      }
    }
  }

  .participants-joined {
    z-index: 500;
    position: absolute;
    border: 1px solid $almost-white;
    border-radius: 50%;
    color: $text-color;
    background: $bg-color;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    @include on("xl", "down") {
      width: 6.6em;
      height: 6.6em;
      top: -3.3em;
      left: -3.3em;
      padding: 3em 0 0 3em;
      > .fa {
        font-size: 1.4em;
      }
      > .small {
        line-height: 1.1;
      }
    }
    @include on("sm", "down") {
      display: none;
      /*width: 5em;
      height: 5em;
      top: -3em;
      left: -3em;
      padding: 2.5em 0 0 2.5em;
      > .fa {
        font-size: 1em;
      }
      > .small {
        line-height: 1;
      }*/
    }
  }

  .text-field-icon {
    font-size: 1.5em;
    line-height: 2.5rem;
    width: 1em;
    text-align: center;
    margin-right: 0.5rem;
  }

  .text-field.is-wrong {
    border-color: $danger;
    border-bottom-left-radius: 0;
    & + .form-error > span {
      background-color: $danger;
      color: $bg-color;
      border-radius: 0 0 $border-radius $border-radius;
      padding: 0 0.25em;
    }
  }
}

.event-card-dummy {
  padding-top: 30%;
  position: relative;
  &.button {
    cursor: pointer;
    border-radius: $border-radius;
    border: 1px solid $medium-gray;
  }
  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
