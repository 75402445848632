.blog-post-preview {
  padding: 0;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  .cover {
    display: block;
    width: 100%;
    aspect-ratio: 4 / 3;
    object-fit: cover;
  }

  > .preview {
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    padding: 1em;
    > p.date {
      font-size: 0.8em;
      margin: 0;
    }
    > p.preview {
      flex-grow: 1;
      margin: 0;
    }
  }
}

.article {
  > .container {
    max-width: 720px;
    p.date {
      font-size: 0.8em;
    }
    img {
      display: block;
      margin: 1em 0 3em 0;
      width: 100%;
    }
  }
}
