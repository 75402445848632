.modal-wrap {
  position: fixed;
  opacity: 0;
  z-index: 950;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;

  @include on("xl", "down") {
    left: calc(1140px + (100vw - 1140px) / 2);
    width: 1140px * 0.6666;
  }
  @include on("lg", "down") {
    left: calc(960px + (100vw - 960px) / 2);
    width: 960px * 0.6666;
  }
  @include on("md", "down") {
    left: calc(720px + (100vw - 720px) / 2);
    width: 720px;
  }
  @include on("sm", "down") {
    left: calc(540px + (100vw - 540px) / 2);
    width: 540px;
  }
  @include on("xs", "down") {
    left: 100vw;
    width: 100vw;
  }

  .modal-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 1em;

    > .modal-title {
      @include on("xl", "down") {
        font-size: 1.953em;
      }
      @include on("sm", "down") {
        font-size: 1.25em;
      }
      font-weight: $bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-grow: 1;
      flex-shrink: 1;
    }

    > .modal-btn {
      @include on("xl", "down") {
        padding: 1.5em 0;
      }
      @include on("sm", "down") {
        padding: 1em 0;
        > .i {
          font-size: 0.8em;
        }
      }
      border: none;
      background: transparent;
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .modal-back-btn {
      @include on("xl", "down") {
        left: calc(-1140px * 1 / 3 - (100vw - 1140px) / 2);
      }
      @include on("lg", "down") {
        left: calc(-960px * 1 / 3 - (100vw - 960px) / 2);
      }
      @include on("md", "down") {
        display: none;
      }
      position: absolute;
      top: 0;
      width: 10em;
      height: 4.5em;
      padding: 1.5em 0;
      border: none;
      background: linear-gradient(90deg, $bg-color 0%, $bg-color 75%, transparent 100%);
    }
  }

  .modal-body {
    @include on("xl", "down") {
      padding: 2.5em 1em;
    }
    @include on("sm", "down") {
      padding: 1em;
    }
    flex-grow: 1;
    overflow-y: auto;
  }
}

.modal-wrap.visible {
  opacity: 1;
  @include on("xl", "down") {
    left: calc(1140px * 1 / 3 + (100vw - 1140px) / 2);
  }
  @include on("lg", "down") {
    left: calc(960px * 1 / 3 + (100vw - 960px) / 2);
  }
  @include on("md", "down") {
    left: calc((100vw - 720px) / 2);
  }
  @include on("sm", "down") {
    left: calc((100vw - 540px) / 2);
  }
  @include on("xs", "down") {
    left: 0;
  }
}

#wrap {
  #header > .navbar,
  #main > .container {
    position: relative;
    left: 0;
    opacity: 1;
    transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  &.shifted {
    #header > .navbar,
    #main > .container {
      @include on("xl", "down") {
        left: -1140px * 0.6666;
      }
      @include on("lg", "down") {
        left: -960px * 0.6666;
      }
      @include on("md", "down") {
        left: -720px;
      }
      @include on("sm", "down") {
        left: -540px;
      }
      @include on("xs", "down") {
        left: -100vw;
      }
      opacity: 0.5;
      &:before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
      }
    }
    #footer {
      opacity: 0;
    }
  }
}
