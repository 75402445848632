label.toggle {
  cursor: pointer;
  display: block;

  > input {
    display: none;

    + span {
      display: inline-block;
      vertical-align: middle;
      height: 1.5em;
      width: 2.6em;
      border-radius: $border-radius;
      position: relative;
      border: 1px solid $medium-gray;
      background-color: $bg-color;
      box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 0.1);
    }

    + span:after {
      display: block;
      content: " ";
      width: 1em;
      height: 1em;
      border-radius: $border-radius;
      position: absolute;
      top: 0.2em;
      left: 0.2em;
      border: 1px solid $medium-gray;
      background: linear-gradient(180deg, $white 0%, $almost-white 100%);
      transition: left 100ms linear;
    }
  }

  > input:checked {
    + span {
      background-color: $accent;
      border: 1px solid darken($accent, 15%);
    }
    + span:after {
      left: 1.3em;
      border: 1px solid darken($accent, 15%);
    }
  }

  > input:disabled {
    + span {
      box-shadow: none;
    }
    + span:after {
      background: $bg-color;
      box-shadow: none;
    }
  }

  > input:checked:disabled {
    + span {
      background-color: transparentize($accent, 0.5);
      box-shadow: none;
    }
    + span:after {
      background: $bg-color;
      box-shadow: none;
    }
  }

  &.is-wrong {
    color: $danger;
    > input + span {
      background-color: $danger;
      border: 1px solid darken($danger, 15%);
    }
    > input + span:after {
      border: 1px solid darken($danger, 15%);
    }
  }
}

label.small:nth-child(1) > span:nth-child(1) {
  font-size: 1.25em;
}

label.small:nth-child(1) > span:nth-child(3) {
  margin-bottom: 0.5em;
}
