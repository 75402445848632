.pagination {
  display: flex;
  flex-flow: row nowrap;

  > button,
  span {
    flex-shrink: 0;
    display: block;
    width: 2.5em;
    height: 2.5em;
    border: none;
    background: transparent;
    font-weight: $regular;
    line-height: 1.5em;
    border-radius: $border-radius;
    padding: 0.5em 0;
    text-align: center;
    text-decoration: none;
    color: inherit;

    @include on("xs", "down") {
      width: 2em;
      height: 2em;
      padding: 0.25em 0;
    }
  }

  > button:hover {
    background: $almost-white;
  }

  > span.current {
    border: 1px solid darken($accent, 15%);
    background-image: linear-gradient(180deg, $accent 0%, darken($accent, 10%) 100%);
    color: $white;
  }
}
