.event-card-design {
  > .modal-body {
    @include on("xl", "down") {
      max-height: calc(100vh - 4.5em);
    }
    @include on("sm", "down") {
      max-height: calc(100vh - 3.5em);
    }
    display: flex;
    flex-flow: column nowrap;

    > .search-panel,
    > .submit-panel {
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .design-examples {
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;

      .event-card-dummy {
        background-size: cover;
        background-position: center center;
      }
    }
  }
}

@mixin light-design {
  color: $text-color-transparent;
  &.event-card-dummy:before {
    background-image: url(../images/icons/event-dummy-light.svg);
  }
  > .btn-design-settings,
  > .participants-joined,
  > .btn-edit-event,
  > .btn-copy-event,
  > .btn-delete-event {
    border-color: transparentize($black, 0.93);
    background-color: transparentize($black, 0.97);
    color: $text-color-transparent;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    &:hover:not(.participants-joined) {
      border-color: transparentize($black, 0.83);
      background-color: transparentize($black, 0.87);
    }
  }
  .btn-default {
    border-color: transparentize($black, 0.86);
    box-shadow: none;
    background: transparentize($black, 0.97);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    color: $text-color;
    &:hover {
      background: transparentize($black, 0.9) !important;
    }
    &:focus {
      outline-color: transparentize($black, 0.7) !important;
    }
  }
  .text-field {
    border-color: transparentize($black, 0.86);
    box-shadow: none;
    background-color: transparentize($black, 0.97);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    color: $text-color-transparent;
    &::placeholder {
      color: transparentize($text-color-transparent, 0.3);
    }
    &:focus {
      outline: none;
      border-color: $text-color-transparent;
    }
  }
}
.design-default.event-card-dummy:before {
  background-image: url(../images/icons/event-dummy-light.svg);
}

@mixin dark-design {
  color: $white;
  &.event-card-dummy:before {
    background-image: url(../images/icons/event-dummy-dark.svg);
  }
  > .btn-design-settings,
  > .participants-joined,
  > .btn-edit-event,
  > .btn-copy-event,
  > .btn-delete-event {
    border-color: transparentize($white, 0.83);
    background-color: transparentize($white, 0.9);
    color: $white;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    &:hover:not(.participants-joined) {
      border-color: transparentize($white, 0.73);
      background-color: transparentize($white, 0.8);
    }
  }
  .btn-default {
    border-color: transparentize($white, 0.25);
    box-shadow: none;
    background: transparentize($white, 0.75);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    color: $white;
    &:hover {
      background: transparentize($white, 0.5) !important;
    }
    &:focus {
      outline-color: transparentize($white, 0.4) !important;
    }
  }
  .text-field {
    border-color: transparentize($white, 0.25);
    box-shadow: none;
    background-color: transparentize($white, 0.75);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    color: $white;
    &::placeholder {
      color: transparentize($white, 0.3);
    }
    &:focus {
      outline: none;
      border-color: $white;
    }
  }
}

// Grayscale
.design-dark {
  @include dark-design;
  background-color: $text-color;
}
.design-silver {
  @include light-design;
  background-color: #dddddd;
}
.design-ski-mountains {
  @include light-design;
  background-image: url(../images/design-ski-mountains.jpg);
}
.design-pastel-flowers {
  @include light-design;
  background-image: url(../images/design-pastel-flowers.jpg);
}
.design-grayscale-beach {
  @include light-design;
  background-image: url(../images/design-grayscale-beach.jpg);
}
.design-gray {
  @include dark-design;
  background-color: #808080;
}
.design-pure-black {
  @include dark-design;
  background-color: #000000;
}

// Red
.design-salmon {
  @include light-design;
  background-color: #ffaeae;
}
.design-red {
  @include dark-design;
  background-color: #ff0000;
}
.design-crimson {
  @include dark-design;
  background-color: #dc143c;
}
.design-purple-gifts {
  @include dark-design;
  background-image: url(../images/design-purple-gifts.jpg);
}
.design-maroon {
  @include dark-design;
  background-color: #800000;
}

// Orange
.design-wheat {
  @include light-design;
  background-color: #f5deb3;
}
.design-wheat-dinner {
  @include dark-design;
  background-image: url(../images/design-wheat-dinner.jpg);
}
.design-orange {
  @include dark-design;
  background-color: #ff4500;
}
.design-chocolate {
  @include dark-design;
  background-color: #4b291d;
}
.design-coins {
  @include dark-design;
  background-image: url(../images/design-coins.jpg);
}
.design-city-sunrise {
  @include dark-design;
  background-image: url(../images/design-city-sunrise.jpg);
}

// Yellow
.design-yellow-pastel {
  @include light-design;
  background-color: #fff7ae;
}
.design-yellow {
  @include light-design;
  background-color: #ffff00;
}
.design-gold {
  @include light-design;
  background-color: #ffd700;
}
.design-khaki {
  @include light-design;
  background-color: #bdb76b;
}

// Green
.design-green-pastel {
  @include light-design;
  background-color: #9bffa5;
}
.design-chartreuse {
  @include light-design;
  background-color: #7fff00;
}
.design-green {
  @include dark-design;
  background-color: #008000;
}
.design-dark-green {
  @include dark-design;
  background-color: #03421b;
}
.design-green-cafe {
  @include dark-design;
  background-image: url(../images/design-green-cafe.jpg);
}

// Blue
.design-sky-blue {
  @include light-design;
  background-color: #bcfbff;
}
.design-yacht-lagoon {
  @include light-design;
  background-image: url(../images/design-yacht-lagoon.jpg);
}
.design-cyan {
  @include light-design;
  background-color: #00e1ff;
}
.design-teal-shore {
  @include dark-design;
  background-image: url(../images/design-teal-shore.jpg);
}
.design-teal {
  @include dark-design;
  background-color: #007180;
}
.design-glass-building {
  @include dark-design;
  background-image: url(../images/design-glass-building.jpg);
}
.design-blue-canvas {
  @include dark-design;
  background-image: url(../images/design-blue-canvas.jpg);
}
.design-navy {
  @include dark-design;
  background-color: #002980;
}

// Violet
.design-lavender {
  @include light-design;
  background-color: #bcc3ff;
}
.design-violet {
  @include dark-design;
  background-color: #7a4aff;
}
.design-indigo {
  @include dark-design;
  background-color: #4b0082;
}
.design-night-city {
  @include dark-design;
  background-image: url(../images/design-night-city.jpg);
}

// Pink
.design-pink-pastel {
  @include light-design;
  background-color: #ffbcf0;
}
.design-sunset-baloons {
  @include dark-design;
  background-image: url(../images/design-sunset-baloons.jpg);
}
.design-pink-chrysanthemum {
  @include dark-design;
  background-image: url(../images/design-pink-chrysanthemum.jpg);
}
.design-pink {
  @include dark-design;
  background-color: #ff1493;
}
