.options {
  display: block;
  width: 100%;

  > input {
    display: none;
  }

  > div {
    height: 100%;
    border: 1px solid $medium-gray;
    position: relative;
    border-radius: $border-radius;
    overflow: hidden;
    cursor: pointer;
    font-weight: $regular;

    &.large-btn {
      background: linear-gradient(180deg, $white 0%, darken($white, 3%) 100%);
      padding: 1.5em 1em;
      text-align: center;
      > .i {
        font-size: 2em;
        display: block;
        margin: 0 auto 1rem;
      }
      &:hover {
        background: linear-gradient(180deg, $white 0%, $almost-white 100%);
      }
    }

    &.medium-btn {
      background: linear-gradient(180deg, $white 0%, darken($white, 3%) 100%);
      padding: 1em 0.8em;
      text-align: center;
      > .i {
        font-size: 1.3em;
        display: block;
        margin: 0 auto 1rem;
      }
      &:hover {
        background: linear-gradient(180deg, $white 0%, $almost-white 100%);
      }
    }

    &.small-btn {
      background: linear-gradient(180deg, $white 0%, darken($white, 3%) 100%);
      padding: 0.6em 0.8em;
      text-align: center;
      height: 2.7em;
      > .i {
        font-size: 1.1em;
        display: block;
        margin: 0 auto;
      }
      &:hover {
        background: linear-gradient(180deg, $white 0%, $almost-white 100%);
      }
    }

    > span.options-num {
      display: block;
      position: absolute;
      width: 5em;
      height: 5em;
      top: -2.5em;
      left: -2.5em;
      padding: 2.875em 0 0 2.25em;
      border-radius: 50%;
      text-align: center;
      background-color: $medium-gray;
      color: $text-color;
    }
  }

  > input:checked + div.medium-btn,
  &.active > div.medium-btn {
    border-color: $accent;
    font-weight: $bold;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 2.5em;
      height: 2.5em;
      top: -1.25em;
      right: -1.25em;
      border-radius: 50%;
      background-color: $accent;
      background-image: url(../images/icons/option-check.embed.svg);
      background-position: center center;
      background-size: contain;
    }
  }

  > input:checked + div.small-btn,
  &.active > div.small-btn {
    border-color: $accent;
    font-weight: $bold;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 2em;
      height: 2em;
      top: -1em;
      right: -1em;
      border-radius: 50%;
      background-color: $accent;
      background-image: url(../images/icons/option-check.embed.svg);
      background-position: center center;
      background-size: contain;
    }
  }

  > input:checked + div,
  &.active > div {
    border-color: $accent;
    font-weight: $bold;
    border-width: 2px;

    > span.options-num {
      color: $white;
      background-color: $accent;
    }

    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 5em;
      height: 5em;
      top: -2.5em;
      right: -2.5em;
      border-radius: 50%;
      background-color: $accent;
      background-image: url(../images/icons/option-check.embed.svg);
      background-position: center center;
      background-size: contain;
    }
  }
}

.options-group {
  display: flex;
  flex-flow: row nowrap;

  .options {
    margin-left: -1px;
  }
  .large-btn {
    border-radius: 0;
  }
  .options:first-child > .large-btn {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
  .options:last-child > .large-btn {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
