.aside-block,
.panel {
  padding: 1em;
  border-radius: $border-radius;
  border: 1px solid $almost-white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  background-color: $white;
  position: relative;

  .joined {
    font-size: 0.85em;

    /* Disabled */
    /*color: $medium-gray;*/
    color: $accent;

    margin-bottom: 0.25em;
  }

  button.dismiss {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5em;
    height: 1.5em;
    line-height: 1;
    border-radius: 50%;
    border: none;
    margin: 0;
    padding: 0;
    color: $danger;
    background: transparent;
  }
}

.aside-block {
  @include on("xl", "down") {
    padding: 2.5em;
  }
  @include on("sm", "down") {
    padding: 1em;
  }
}

.smart-tip {
  position: sticky;
  padding: 1em;
  top: 8em;

  &.smart-tip-enter-to,
  &.smart-tip-leave {
    opacity: 1;
  }
  &.smart-tip-leave-to,
  &.smart-tip-enter {
    opacity: 0;
  }
  &.smart-tip-enter-active {
    transition: opacity 1.7s ease-in;
  }
  &.smart-tip-leave-active {
    transition: none;
  }
  .premium-ad {
    color: #75c378;
  }
}

.invitee-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;

  /* White */
  background: #ffffff;

  /* Stroke */
  border: 1px solid #f0f0f0;
  box-sizing: border-box;

  /* Shadow */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  h4 {
    font-family: "Signika";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 2rem;

    /* Text color */
    color: #373737;
  }
}
