$height: 1.5em;

.i {
  display: inline-block;
  vertical-align: middle;
  height: $height;
  background-color: transparent;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &.lg {
    font-size: 1.5em;
  }
  &.x2 {
    font-size: 2em;
  }
  &.google-full {
    width: $height * 2.82;
    background-image: url(../images/icons/google-full.embed.svg);
  }
  &.google {
    position: relative;
    top: $height * (-0.0833);
    width: $height;
    background-image: url(../images/icons/google.embed.svg);
  }
  &.google-calendar {
    width: $height;
    background-image: url(../images/icons/google-calendar.embed.svg);
  }
  &.apple-full {
    position: relative;
    top: $height * (-0.0833);
    width: $height * 2.92;
    background-image: url(../images/icons/apple-full.embed.svg);
  }
  &.microsoft-full {
    width: $height * 4.68;
    background-image: url(../images/icons/microsoft-full.embed.svg);
  }
  &.times-lg {
    width: $height;
    background-image: url(../images/icons/times-lg.embed.svg);
  }
  &.back-lg {
    width: $height * 0.58;
    background-image: url(../images/icons/back-lg.embed.svg);
  }
  &.logo {
    width: $height * 2.39;
    background-image: url(../images/icons/logo.embed.svg);
  }
  &.logo-premium {
    width: $height * 2.39;
    background-image: url(../images/icons/logo-premium.embed.svg);
  }
  &.unlimited-participants {
    width: $height * 1.87;
    background-image: url(../images/icons/unlimited-participants.embed.svg);
  }
  &.limited-participants {
    width: $height * 1.87;
    background-image: url(../images/icons/limited-participants.embed.svg);
  }
  &.only-by-emails {
    width: $height * 1.87;
    background-image: url(../images/icons/only-by-emails.embed.svg);
  }
  &.exact-time {
    width: $height * 1.87;
    background-image: url(../images/icons/exact-time.embed.svg);
  }
  &.time-interval {
    width: $height * 1.87;
    background-image: url(../images/icons/time-interval.embed.svg);
  }
  &.time-options {
    width: $height * 1.87;
    background-image: url(../images/icons/time-options.embed.svg);
  }
  &.you-participant {
    width: $height * 1.87;
    background-image: url(../images/icons/you-participant.embed.svg);
  }
  &.you-voted {
    width: $height * 1.87;
    background-image: url(../images/icons/you-voted.embed.svg);
  }
  &.you-organizer {
    width: $height * 1.87;
    background-image: url(../images/icons/you-organizer.embed.svg);
  }
  &.event-draft {
    width: $height * 1.87;
    background-image: url(../images/icons/event-draft.embed.svg);
  }
}
