.badge {
  display: inline-block;
  font-weight: $regular;
  line-height: 1.5em;
  border-radius: $border-radius;
  background: $almost-white;
  white-space: nowrap;
  padding: 0.5em;
  padding-right: 2.5em;
  position: relative;

  > button {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    width: 1.5em;
    height: 1.5em;
    line-height: 1;
    padding: 0.25em 0;
    border-radius: 50%;
    border: none;
    margin: 0;
    padding: 0;
    color: $danger;
    background: transparent;
  }

  &.sm {
    display: inline;
    padding: 0 0.25em;
    .fa {
      font-size: 0.85em;
    }
  }
}

.badges-list {
  display: flex;
  flex-flow: row wrap;
  margin: -0.25em;
  > * {
    margin: 0.25em;
  }
}
