#main.landing {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;

  .container {
    padding: 0 !important;
  }

  .yet-another-calendar {
    margin-top: 2.5em;

    .image {
      @include on("sm", "down") {
        width: 18em;
      }
      @include on("md") {
        display: block;
        max-width: 100%;
      }
    }

    .text {
      @include on("sm", "down") {
        text-align: center;
        padding: 1em;
      }
      @include on("md") {
        padding: 3em 2em 1em 1em;
      }
      @include on("lg") {
        padding: 4em 2.5em 1em 1em;
        h2 {
          font-size: 3em;
        }
        p.lead {
          font-size: 2em;
        }
        p {
          font-size: 1.5em;
        }
      }
      @include on("xl") {
        padding: 5em 5em 2.5em 2.5em;
        p {
          margin: 1em 0;
        }
      }
    }

    button {
      @include on("lg") {
        font-size: 1.5em;
      }
    }
  }

  .powered-by-ai {
    .curved-shadow {
      transform: scaleX(-1);
      background-image: url(../images/landing/curved-shadow.png);
      background-repeat: no-repeat;
      background-position: top center;
      @include on("sm", "down") {
        padding-top: 6rem;
        background-size: 100% 6rem;
      }
      @include on("md") {
        padding-top: 8rem;
        background-size: 100% 8rem;
      }
      @include on("lg") {
        padding-top: 10.5rem;
        background-size: 100% 10.5rem;
      }
      @include on("xl") {
        padding-top: 12.5rem;
        background-size: 100% 12.5rem;
      }
    }
    .image {
      @include on("sm", "down") {
        width: 18em;
      }
      @include on("md") {
        display: block;
        max-width: 100%;
      }
    }
    .text {
      padding: 1em;
      @include on("sm", "down") {
        text-align: center;
      }
      @include on("md") {
        padding-left: 3em;
      }
      @include on("lg") {
        padding-left: 4em;
      }
      @include on("xl") {
        padding-left: 5em;
        p {
          margin: 1em 0;
        }
      }
    }
  }

  .four-steps {
    h2 {
      text-align: center;
      background-image: url(../images/landing/curved-shadow.png);
      background-repeat: no-repeat;
      background-position: top center;

      @include on("sm", "down") {
        font-size: 1.5em;
        padding: 1rem;
        padding-top: 9rem;
        background-size: 100% 6rem;
      }
      @include on("md") {
        font-size: 3em;
        padding: 1.5rem;
        padding-top: 10rem;
        background-size: 100% 8rem;
      }
      @include on("lg") {
        padding-top: 12.5rem;
        background-size: 100% 10.5rem;
      }
      @include on("xl") {
        padding-top: 15rem;
        background-size: 100% 12.5rem;
      }
    }

    .step-card {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      text-align: center;
      padding: 2.5em 0;

      @include on("sm", "down") {
        border-radius: $border-radius;
        border: 1px solid $almost-white;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
      }

      p {
        margin: 0;
        padding: 0 2.5em;
      }

      .card-img {
        height: 15.8em;
        > img {
          display: block;
          height: 100%;
        }
      }
    }

    .row {
      position: relative;
    }

    @include on("xl", "only") {
      .row > :not(div:last-child)::after {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -1px;
        width: 1px;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
        background: linear-gradient(0deg, transparent, $almost-white 50%, transparent);
      }
    }

    @include on("lg", "md") {
      .row::before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 1px;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
        background: linear-gradient(0deg, transparent, $almost-white 50%, transparent);
      }
      .row::after {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        height: 1px;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
        background: linear-gradient(90deg, transparent, $almost-white 50%, transparent);
      }
    }
  }

  .faq {
    margin-top: 1em;
    padding-top: 3em;
    @include on("md") {
      margin-top: 3em;
      padding-top: 4em;
    }
    @include on("lg") {
      margin-top: 4em;
    }
    @include on("xl") {
      margin-top: 5.5em;
      padding-top: 5.5em;
    }
    position: relative;

    &::after {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-position: top center;
      background-repeat: no-repeat;
      background-image: url(../images/landing/arc-down.png);
      @include on("sm", "down") {
        height: 3.5em;
        background-size: 100% 3.5em;
      }
      @include on("md") {
        height: 4.5em;
        background-size: 100% 4.5em;
      }
      @include on("lg") {
        height: 6em;
        background-size: 100% 6em;
      }
      @include on("xl") {
        height: 7em;
        background-size: 100% 7em;
      }
    }

    h2 {
      text-align: center;
      margin-bottom: 1rem;
      @include on("md") {
        margin-bottom: 3rem;
      }
    }

    .panel {
      padding: 2.5em;
      h3 {
        margin: 0;
        cursor: pointer;
        &:hover {
          color: $accent;
        }
        > i {
          transform-origin: center center;
          transform: rotate(0);
          transition: transform 300ms ease;
        }
        + div {
          margin-top: 1em;
        }
      }
      &.open > h3 > i {
        transform: rotate(90deg);
      }
    }

    @include on("sm", "down") {
      .row {
        margin-left: 0;
        margin-right: 0;
        > div {
          padding-left: 1em;
          padding-right: 1em;
        }
      }
    }
  }

  .pre-footer {
    text-align: center;
    margin-top: 3em;
    padding: 3em 1em;
    @include on("md") {
      padding-top: 4em;
    }
    @include on("xl") {
      padding-top: 5.5em;
    }
    position: relative;

    &::after {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-position: top center;
      background-repeat: no-repeat;
      background-image: url(../images/landing/arc-up.png);
      @include on("sm", "down") {
        height: 3.5em;
        background-size: 100% 3.5em;
      }
      @include on("md") {
        height: 4.5em;
        background-size: 100% 4.5em;
      }
      @include on("lg") {
        height: 6em;
        background-size: 100% 6em;
      }
      @include on("xl") {
        height: 7em;
        background-size: 100% 7em;
      }
    }

    .logo {
      font-size: 4em;
    }
  }

  .pricing {
    margin-top: 2em;
  }
}

.tag-example-arrow__path,
.tag-example-arrow__head {
  stroke: $medium-gray;
  fill: transparent;
  stroke-width: 2;
  stroke-linecap: round;
}
.tag-example-arrow__path {
  stroke-dasharray: 8;
}
