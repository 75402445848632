.votes-result {
  display: flex;
  flex-flow: row nowrap;
  border-radius: $border-radius;
  padding: 0 0.25em;
  &.clickable {
    cursor: pointer;
  }
  > .title {
    flex-grow: 1;
  }
  > .percentage {
    flex-grow: 0;
  }
}
