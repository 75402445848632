ul.tabs {
  list-style: none;
  margin: 0;
  padding: 0 1em;
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid $medium-gray;

  > li {
    margin-right: 0.25em;
    font-weight: $regular;
    line-height: 1.5em;
    height: 2.5em;
    text-align: center;
    white-space: nowrap;
    min-width: 6em;
    padding: 0.5em 1em;
    border-radius: $border-radius $border-radius 0 0;
    color: $text-color;
    cursor: pointer;
    border: 1px solid $medium-gray;
    position: relative;
    top: 1px;

    &.active {
      border-bottom: none;
      border-color: $medium-gray;
      background-image: linear-gradient(180deg, $white 0%, $almost-white 50%, $white 100%);
    }

    &:hover:not(.active) {
      background-image: linear-gradient(180deg, $white 0%, $almost-white 100%);
    }
  }
}
