.calendar-input {
  padding: 1em;
  border: 1px solid $almost-white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius;
  background: $bg-color;
  position: relative;

  button.dismiss {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    color: $danger;
    white-space: nowrap;
    position: absolute;
    top: 0.25em;
    right: 0.25em;
    width: 1em;
    height: 1em;
    line-height: 1;
  }

  .year-month {
    margin-bottom: 0.5em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    > .input-group {
      flex-grow: 1;
    }
    > .btn-text {
      padding: 0 0.5em;
    }
  }

  ul.week-days {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    > li {
      width: 1.5rem;
      height: 1.5rem;
      text-align: center;
      font-size: 0.8em;

      &.weekend {
        color: $danger;
      }
    }
  }

  .days {
    > .week {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      > li {
        width: 1.5em;
        height: 1.5em;
        text-align: center;
        border-radius: $border-radius;

        &:not(.disabled) {
          cursor: pointer;
          &.not-this-month {
            color: $medium-gray;
          }
          &.weekend {
            color: $danger;
          }
          &.current-date {
            font-weight: $bold;
            background: $almost-white;
          }
          &.selected,
          &.selected:hover {
            color: $white;
            background: $accent;
          }
          &:hover {
            background: $almost-white;
            color: inherit;
          }
        }

        &.disabled {
          color: $medium-gray;
          &.current-date {
            font-weight: $bold;
            background: $almost-white;
          }
        }
      }
    }
  }
  .time {
    margin-top: 0.5em;
  }
}
