$min-width-of: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$max-width-of: (
  xs: 575px,
  sm: 767px,
  md: 991px,
  lg: 1199px,
  xl: 99999px,
);

@mixin on($device1, $ruleOrDevice2: "up", $device3: "", $device4: "") {
  @if $ruleOrDevice2 == "up" or $ruleOrDevice2 == "down" or $ruleOrDevice2 == "only" {
    $rule: $ruleOrDevice2;
    @if $rule == "up" {
      @media (min-width: map-get($min-width-of, $device1)) {
        @content;
      }
    }
    @if $rule == "down" {
      @media (max-width: map-get($max-width-of, $device1)) {
        @content;
      }
    }
    @if $rule == "only" {
      @media (min-width: map-get($min-width-of, $device1)) and (max-width: map-get($max-width-of, $device1)) {
        @content;
      }
    }
  } @else {
    $device2: $ruleOrDevice2;
    @if $device4 != "" {
      @media (min-width: map-get($min-width-of, $device1)) and (max-width: map-get($max-width-of, $device1)),
        (min-width: map-get($min-width-of, $device2)) and (max-width: map-get($max-width-of, $device2)),
        (min-width: map-get($min-width-of, $device3)) and (max-width: map-get($max-width-of, $device3)),
        (min-width: map-get($min-width-of, $device4)) and (max-width: map-get($max-width-of, $device4)) {
        @content;
      }
    }
    @if $device4 == "" and $device3 != "" {
      @media (min-width: map-get($min-width-of, $device1)) and (max-width: map-get($max-width-of, $device1)),
        (min-width: map-get($min-width-of, $device2)) and (max-width: map-get($max-width-of, $device2)),
        (min-width: map-get($min-width-of, $device3)) and (max-width: map-get($max-width-of, $device3)) {
        @content;
      }
    }
    @if $device4 == "" and $device3 == "" {
      @media (min-width: map-get($min-width-of, $device1)) and (max-width: map-get($max-width-of, $device1)),
        (min-width: map-get($min-width-of, $device2)) and (max-width: map-get($max-width-of, $device2)) {
        @content;
      }
    }
  }
}

$font-family: "Signika";
$bold: 500;
$regular: 300;

// Main colors
$text-color: #373737;
$text-color-transparent: rgba(0, 0, 0, 0.78);
$accent: #74c378;
$danger: #ec3c2f;
$warning: #fc8c2a;
$bg-color: #ffffff;

// Gray colors
$white: #ffffff;
$almost-white: #f0f0f0;
$light-gray: #e8e8e8;
$medium-gray: #d3d3d3;
$transparent-gray: rgba(0, 0, 0, 0.1);
$black: #000000;

// Borders
$border-radius: 0.25rem;
